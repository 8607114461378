import $ from 'jquery';
import stickyFooter from 'sticky-footer';
import SmoothScroll from 'smooth-scroll';
import Tabby from 'tabbyjs';
import headroomJS from "headroom.js";
import LazyLoad from "vanilla-lazyload";
import 'bootstrap/js/src/dropdown';
import addBrowserClass from './modules/browser';
import fontSetting from './modules/font';

// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
stickyFooter.init({
    selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
    selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
    callback: function() {}, // Runs after the footer is stuck
});
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]', {
    header: '.l-header',
    speed: 50,
    speedAsDuration: false
});
// scroll.animateScroll(80);
// -------------------------------------------------------------------
// Tabby JS
// -------------------------------------------------------------------
if ($('[data-tabs]').length) {
    const tabs = new Tabby('[data-tabs]');
} else {}
if ($('[data-tabs-Bd]').length) {
    const tabsBd = new Tabby('[data-tabs-Bd]');
} else {}
if ($('[data-tabsCompass]').length) {
    const tabsBd = new Tabby('[data-tabsCompass]');
} else {}
// -------------------------------------------------------------------
// LazyLoad
// -------------------------------------------------------------------
const lazyContent = new LazyLoad({
    elements_selector: "img.lazy",
    use_native: true // <-- there you go
});

const lazyBackground = new LazyLoad({
    elements_selector: "iframe.lazy, video.lazy, div.lazy"
        // DON'T PASS use_native: true HERE
});
// -------------------------------------------------------------------
// jQuery
// -------------------------------------------------------------------
// $(function() {});
$(function() {
    setInterval(function() {
        var now = new Date();
        var y = now.getFullYear();
        var m = now.getMonth() + 1;
        $('.js-presentDate').text(y + '.' + m);
    }, 0);

    var images = [
        '/assets/img/cover_1.jpg',
        '/assets/img/cover_2.jpg',
        '/assets/img/cover_3.jpg',
        '/assets/img/cover_4.jpg'
    ];
    var randImg = images[Math.floor(Math.random() * images.length)];
    $('.js-random').attr('src', randImg);
});